import { type FC, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useSession } from 'next-auth/react'
import { Clear, ExpandMore } from '@mui/icons-material'
import { sanitize } from 'isomorphic-dompurify'

import BaseDialog from '@/components/ui/baseComponents/BaseDialog'
import { useUserProvider } from '@/store/userProvider'
import { getBrowserInfo } from '@/helpers/browser'
import Row from '@/components/ui/layout/Row'
import useToggleState from '@/hooks/useToggleState'
import Link from '@/components/ui/Link'

export const EnvironmentAlert: FC = () => {
  const { toggleState: feedbackModalState, toggleStateHandler: toggleFeedbackModalState } = useToggleState(false)

  const messages: Record<string, JSX.Element> = {
    development: (
      <>
        You are in the Beta Dev website state. This means the code is not stable and transactions are not live. There is
        limited ability to this website. If you find bugs, please report them.
      </>
    ),
    test: (
      <>
        You are in the Beta Testing website state. There is limited ability to this website. Transactions will not be
        processed. Please report any bugs or functionality issues before we send the site live.
      </>
    ),
    production: (
      <>
        Thanks for checking out our new website! It&apos;s still in beta, but all buying and selling you do is real.
        Certain features may not be available yet, but you can always use the{' '}
        <Link href="https://www.comc.com/">regular COMC site</Link> while we work on the new one. <br /> Something not
        quite right? Let us know so we can fix it!
      </>
    ),
  }

  return (
    <Alert variant="banner" color="attention" icon={false}>
      {messages[process.env.NEXT_PUBLIC_APP_ENV] || messages.development}
      <Button variant="text" onClick={() => toggleFeedbackModalState()}>
        Report Feedback
      </Button>
      <FeedbackDialog modalState={feedbackModalState} toggleModalState={toggleFeedbackModalState} />
    </Alert>
  )
}

const FeedbackDialog: FC<{ modalState: boolean; toggleModalState: () => void }> = ({
  modalState,
  toggleModalState,
}) => {
  const recipient = process.env.NEXT_PUBLIC_FEEDBACK_EMAIL
  const { userSummary } = useUserProvider()
  const { data: session } = useSession()
  const [contactEmail, setContactEmail] = useState('')
  const [comment, setComment] = useState('')

  const makeMailToLink = (env: string, body: string) => {
    const protocol = 'mailto:'
    const subject = `[Bug Report][${env}] - `
    return `${protocol}${recipient}?subject=${subject}&body=${body}`
  }

  const generateEmailBody = useCallback(() => {
    const username = userSummary?.userName || session?.user?.name || 'Unauthenticated User'
    return `Username: ${username}<br>${
      contactEmail && `Contact Email: ${contactEmail}<br> `
    }Comment: ${comment}<br><br>Page URL: ${
      window.location.href
    }<br>Browser Info: <br>${getBrowserInfo()}<br>Page Dimensions(WxH): ${window.innerWidth}x${window.innerHeight}`
  }, [contactEmail, comment, userSummary, session])

  const onClose = () => {
    setComment('')
    setContactEmail('')
  }

  return (
    <BaseDialog open={modalState}>
      <DialogTitle id="feedback-form-dialog-title" textAlign="center" variant="h5">
        Report feedback
      </DialogTitle>
      <DialogContent sx={{ mx: 2 }}>
        <IconButton
          color="primary"
          sx={{ position: 'absolute', top: 4, right: 4 }}
          onClick={() => {
            toggleModalState()
            onClose()
          }}
        >
          <Clear fontSize="small" />
        </IconButton>
        <Stack spacing={2} mt={1}>
          <FormControl fullWidth={true}>
            <TextField
              fullWidth
              variant="outlined"
              color="primary"
              autoComplete="off"
              label="Your Email"
              value={contactEmail}
              onChange={(e) => {
                setContactEmail(e.target.value)
              }}
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              fullWidth
              required
              multiline
              rows={4}
              variant="outlined"
              color="primary"
              autoComplete="off"
              label="Your feedback"
              helperText={`Please give us as much context as possible. For example, give us step-by-step instructions of how you encountered what went wrong. This will help us take care of it faster. When you're done, press "Generate email." An email addressed to us will automatically appear on your screen. If you took any screenshots or screen recordings attach them to the email before sending it to us. Note: You still have to send this email for us to get it. Once you've sent the email, come back here and press "Done" to close this message. If we have any questions about your feedback, we'll email you.`}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value)
              }}
            />
          </FormControl>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Row spacing={1} alignItems="startn">
                <Typography whiteSpace="nowrap">Troubleshooting</Typography>
                <Typography variant="caption">
                  If pressing &quot;Generate email&quot; did not work, here&apos;s how to share your feedback. First,
                  press the arrow next to this message to expand it. Then, copy the text shown and send it with any
                  screenshots or screen recordings, as well as a description of what went wrong, to{' '}
                  <Link href={`mailto:${process.env.NEXT_PUBLIC_FEEDBACK_EMAIL}`}>
                    {process.env.NEXT_PUBLIC_FEEDBACK_EMAIL}
                  </Link>{' '}
                </Typography>
              </Row>
            </AccordionSummary>
            <AccordionDetails>
              <Typography dangerouslySetInnerHTML={{ __html: sanitize(generateEmailBody()) }}></Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Stack alignItems="baseline" spacing={1} justifyContent="end">
          <Row spacing={2}>
            <Button
              onClick={() => {
                toggleModalState()
                onClose()
              }}
            >
              Done
            </Button>
            <Button
              variant="contained"
              href={makeMailToLink(process.env.NEXT_PUBLIC_APP_ENV, generateEmailBody().replaceAll('<br>', `%0D`))}
            >
              Generate Email
            </Button>
          </Row>
        </Stack>
      </DialogActions>
    </BaseDialog>
  )
}
