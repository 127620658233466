import type { RecentSearch, RecentSearchResponse } from '@/interfaces/user/recentSearch'

import { recentSearchesListURL } from '@/constants/apiURLs'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { SearchSuggestionType } from '@/interfaces/autocompleteSearchOption'

const useRecentSearches = () => {
  const { data: recentSearches, ...props } = useAuthenticatedQuery<RecentSearchResponse, unknown, RecentSearch[]>(
    [RequestMethods.GET],
    RequestMethods.GET,
    recentSearchesListURL(),
    {
      queryKey: ['recentSearches'],
      select: (data) => {
        return data.recentSearches
          .toSorted((a, b) => b.lastSearchDate.localeCompare(a.lastSearchDate))
          .map(
            (search) =>
              ({
                id: search.id,
                title: search.searchDescription,
                params: { searchTerm: search.searchDescription },
                type: SearchSuggestionType.RECENT_SEARCH,
              }) as RecentSearch
          )
      },
      refetchOnWindowFocus: true,
      initialData: { hasNewResultCounts: false, recentSearches: [] },
    }
  )

  return { recentSearches, ...props }
}

export default useRecentSearches
