import type { FC } from 'react'

import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'

import FOOTER_SECTIONS from '@/constants/footerSections'
import SOCIAL_MEDIA_LINKS from '@/constants/socialMedia'
import theme from '@/themes/comcTheme'
import Link from '@/components/ui/Link'
import FooterSection from './FooterSection'

const Footer: FC = () => {
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      bgcolor="brandGray.main"
      width="100%"
      display="flex"
      alignContent="center"
      flexDirection="column"
      pt={5}
      pb={4}
      px={2}
      justifyContent="center"
    >
      <Grid container rowSpacing={2} sx={{ textAlign: { xs: 'center', lg: 'left' } }} maxWidth="80%" margin=" 0 auto">
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FooterSection {...FOOTER_SECTIONS['About Us']} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FooterSection {...FOOTER_SECTIONS.Buy} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FooterSection {...FOOTER_SECTIONS.Sell} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FooterSection {...FOOTER_SECTIONS['Other Services']} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FooterSection {...FOOTER_SECTIONS['Business Partners']} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack>
            <FooterSection {...FOOTER_SECTIONS.Connect} />
            <Stack direction="row" justifyContent={{ xs: 'center', lg: 'flex-start' }}>
              <IconButton href={SOCIAL_MEDIA_LINKS.TWITTER} target="_" aria-label="COMC's Twitter">
                <Twitter sx={{ color: '#FFF' }} />
              </IconButton>
              <IconButton href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_" aria-label="COMC's Facebook">
                <Facebook sx={{ color: '#FFF' }} />
              </IconButton>
              <IconButton href={SOCIAL_MEDIA_LINKS.YOUTUBE} target="_" aria-label="COMC's YouTube">
                <YouTube sx={{ color: '#FFF' }} />
              </IconButton>
              <IconButton href={SOCIAL_MEDIA_LINKS.INSTAGRAM} target="_" aria-label="COMC's Instagram">
                <Instagram sx={{ color: '#FFF' }} />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {/* TODO: The Link colors here have poor contrast */}
      <Stack alignItems="center" spacing={2} textAlign="center" marginTop={2}>
        {isSmallScreenSize ? (
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography color="brandGray.contrastText" fontSize=".8em">
                © {new Date().getFullYear()} Check Out My LLC, All Rights Reserved
              </Typography>
            </Grid>
            <Grid item xs={0} md={1}>
              <Divider variant="middle" orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={5} md={3} textAlign="right">
              <Link href="" fontSize=".8em">
                Privacy Policy
              </Link>
              {/*  TODO: Add Page */}
            </Grid>
            <Grid item xs={2} md={1}>
              <Divider variant="middle" orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={4} md={3} textAlign="left">
              <Link href="" fontSize=".8em">
                User Agreement
              </Link>
              {/*  TODO: Add Page */}
            </Grid>
          </Grid>
        ) : (
          <Stack direction="row" spacing={1}>
            <Typography color="brandGray.contrastText" fontSize=".8em">
              © {new Date().getFullYear()} Check Out My LLC, All Rights Reserved
            </Typography>
            <Divider variant="middle" orientation="vertical" flexItem />
            <Link href="" fontSize=".8em">
              Privacy Policy
            </Link>
            {/*  TODO: Add Page */}
            <Divider variant="middle" orientation="vertical" flexItem />
            <Link href="" fontSize=".8em">
              User Agreement
            </Link>
            {/*  TODO: Add Page */}
          </Stack>
        )}
        <Typography color="brandGray.contrastText" fontSize=".8em">
          <Link href="/">COMC.com</Link> is doing business for Check Out My LLC and is utilizing patented technology.
        </Typography>
      </Stack>
    </Box>
  )
}

export default Footer
