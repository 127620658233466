import type { ContactInformation } from '@/interfaces/user/contactInformation'

export const isAddressEntered = (address?: ContactInformation): boolean => {
  if (!address) {
    return false
  }
  const { firstName, lastName, address1, city, state, countryCode, postalCode, phoneNumber } = address
  const isAddressValid = !!(
    firstName &&
    lastName &&
    address1 &&
    city &&
    state &&
    countryCode &&
    postalCode &&
    phoneNumber
  )

  return isAddressValid
}
