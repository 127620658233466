import type { ContactInformation } from '@/interfaces/user/contactInformation'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import { userQueryKeys } from '@/interfaces/user/userQueries'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { userContactInfoURL } from '@/constants/apiURLs'

const useContactInformation = () => {
  const { data: contactInformation } = useAuthenticatedQuery<ContactInformation>(
    [RequestMethods.GET],
    RequestMethods.GET,
    userContactInfoURL,
    {
      queryKey: [userQueryKeys.GetContactInformation],
    }
  )

  return { contactInformation }
}

export default useContactInformation
