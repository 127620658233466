import type { SearchResultsData } from '@/interfaces/searchResult'
import type { SearchParams } from '@/interfaces/searchRequest'
import type { ITEM_SORT } from '@/constants/sort'
import type { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { useQueryState, parseAsInteger } from 'nuqs'
import { useSession } from 'next-auth/react'
import { isEqual } from 'lodash'

import { clientAPIRequest, searchParamToAvailabilityOption, searchParamToListingFormatOption } from '@/helpers'
import { DEFAULT_SEARCH_REQUEST_VALUES } from '@/constants'
import { RequestMethods } from '@/interfaces/api/requestMethods'

type SearchResultsContext = {
  searchResults?: SearchResultsData
  searchResultsLoading: boolean
  searchParams: SearchParams
  updateSearchParams: (newSearchParams: SearchParams) => void
}

const searchResultsContext = createContext<SearchResultsContext>({
  searchResults: undefined,
  searchResultsLoading: false,
  searchParams: DEFAULT_SEARCH_REQUEST_VALUES(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSearchParams: () => {},
})

export default function SearchResultsProvider({
  initialSearchParams,
  children,
}: PropsWithChildren<{ initialSearchParams: SearchParams | null }>): JSX.Element {
  const [recentSearchID, setRecentSearchID] = useQueryState('recentSearchID')
  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_REQUEST_VALUES(true),
    ...initialSearchParams,
  })
  const [searchTerm, setSearchTerm] = useQueryState('searchTerm', { defaultValue: '' })
  const [sort, setSort] = useQueryState('sort', { defaultValue: DEFAULT_SEARCH_REQUEST_VALUES(true).sort })
  const [attributes, setAttributes] = useQueryState('attributes', { history: 'push' })
  const [availability, setAvailability] = useQueryState('availability', {
    history: 'push',
    defaultValue: DEFAULT_SEARCH_REQUEST_VALUES().availability,
  })
  const [listingFormat, setListingFormat] = useQueryState('listingFormat', {
    history: 'push',
    defaultValue: DEFAULT_SEARCH_REQUEST_VALUES().listingFormat,
  })
  const [playerId, setPlayerId] = useQueryState('playerId', parseAsInteger.withOptions({ history: 'push' }))
  const [teamId, setTeamId] = useQueryState('teamId', parseAsInteger.withOptions({ history: 'push' }))
  const [ownerName, setOwnerName] = useQueryState('ownerName', { history: 'push' })
  const [gradeActionPath, setGradeActionPath] = useQueryState('gradeActionPath', { history: 'push' })
  const [graderPath, setGraderPath] = useQueryState('graderPath', { history: 'push' })
  const [gradePath, setGradePath] = useQueryState('gradePath', { history: 'push' })
  const [category, setCategory] = useQueryState('sportPath', { history: 'push' })
  const [decadePath, setDecadePath] = useQueryState('decadePath', { history: 'push' })
  const [yearPath, setYearPath] = useQueryState('yearPath', { history: 'push' })
  const [parentSetPath, setParentSetPath] = useQueryState('parentSetPath', { history: 'push' })
  const [setPath, setSetPath] = useQueryState('setPath', { history: 'push' })
  const [subSearch, setSubSearch] = useQueryState('subSearch', { history: 'push' })
  const [pageNum, setPageNum] = useQueryState('pageNum', parseAsInteger.withOptions({ history: 'push' }).withDefault(1))
  const { data: session } = useSession()
  const prevSearchID = useRef<string | null>(recentSearchID)

  const fetchSearchResults = useCallback(
    async () =>
      clientAPIRequest<SearchResultsData, unknown>('/api/search', RequestMethods.GET, { ...searchParams }, session),
    [searchParams, session]
  )

  const {
    data: searchResults,
    isFetching: searchResultsLoading,
    refetch: updateSearchResults,
  } = useQuery({
    queryKey: ['searchResults'],
    queryFn: () => fetchSearchResults(),
    refetchOnWindowFocus: false,
    initialData: undefined,
    placeholderData: (prev: SearchResultsData | undefined) => prev,
    enabled: false,
  })

  useEffect(() => {
    if (searchResults && searchResults.parameters) {
      const { parameters } = searchResults
      if (parameters.search) {
        setSearchTerm(parameters.search, { history: 'replace' })
      }
      if (parameters.sportPath) {
        setCategory(parameters.sportPath, { history: 'replace' })
      }
      if (parameters.decadePath) {
        setDecadePath(parameters.decadePath, { history: 'replace' })
      }
      if (parameters.yearPath) {
        setYearPath(parameters.yearPath, { history: 'replace' })
      }
      if (parameters.parentSetPath) {
        setParentSetPath(parameters.parentSetPath, { history: 'replace' })
      }
      if (parameters.setPath) {
        setSetPath(parameters.setPath, { history: 'replace' })
      }
      if (parameters.owner) {
        setOwnerName(parameters.owner, { history: 'replace' })
      }
      if (parameters.playerId) {
        setPlayerId(parameters.playerId, { history: 'replace' })
      }
      if (parameters.teamId) {
        setTeamId(parameters.teamId, { history: 'replace' })
      }
      if (parameters.gradeActionPath) {
        setGradeActionPath(parameters.gradeActionPath, { history: 'replace' })
      }
      if (parameters.gradePath) {
        setGradePath(parameters.gradePath, { history: 'replace' })
      }
      if (parameters.graderPath) {
        setGraderPath(parameters.graderPath, { history: 'replace' })
      }
      if (parameters.subSearch) {
        setSubSearch(parameters.subSearch, { history: 'replace' })
      }
      if (parameters.sort) {
        setSort(parameters.sort, { history: 'replace' })
      }
      if (parameters.attributePaths) {
        setAttributes(parameters.attributePaths, { history: 'replace' })
      }
      if (parameters.soldOutOption) {
        setAvailability(searchParamToAvailabilityOption(parameters.soldOutOption), { history: 'replace' })
      }
      if (parameters.listingFormat) {
        setListingFormat(searchParamToListingFormatOption(parameters.listingFormat), { history: 'replace' })
      }
      setPageNum(1, { history: 'replace' })
      setRecentSearchID(null)
    }
  }, [
    searchResults,
    setAttributes,
    setAvailability,
    setCategory,
    setDecadePath,
    setGradeActionPath,
    setGradePath,
    setGraderPath,
    setListingFormat,
    setOwnerName,
    setPageNum,
    setParentSetPath,
    setPlayerId,
    setRecentSearchID,
    setSearchTerm,
    setSetPath,
    setSort,
    setSubSearch,
    setTeamId,
    setYearPath,
  ])

  const updateSearchParams = useCallback(
    (newSearchParams: SearchParams) => {
      if (!searchParams) return
      prevSearchID.current = null
      if (searchParams.searchTerm !== newSearchParams.searchTerm) {
        setSearchTerm(newSearchParams.searchTerm)
      }
      if (searchParams.sort !== newSearchParams.sort) {
        setSort(newSearchParams.sort)
      }
      if (searchParams.availability !== newSearchParams.availability) {
        setAvailability(newSearchParams.availability)
      }
      if (searchParams.listingFormat !== newSearchParams.listingFormat) {
        setListingFormat(newSearchParams.listingFormat)
      }
      if (searchParams.pageNum !== newSearchParams.pageNum) {
        setPageNum(newSearchParams.pageNum)
      }
      if (!isEqual(searchParams.attributePaths, newSearchParams.attributePaths)) {
        if (newSearchParams.attributePaths?.length) {
          setAttributes(newSearchParams.attributePaths)
        } else {
          setAttributes(null)
        }
      }
      if (searchParams.teamId !== newSearchParams.teamId) {
        setTeamId(newSearchParams.teamId)
      }
      if (searchParams.ownerName !== newSearchParams.ownerName) {
        setOwnerName(newSearchParams.ownerName)
      }
      if (searchParams.gradeActionPath !== newSearchParams.gradeActionPath) {
        setGradeActionPath(newSearchParams.gradeActionPath)
      }
      if (searchParams.graderPath !== newSearchParams.graderPath) {
        setGraderPath(newSearchParams.graderPath)
      }
      if (searchParams.gradePath !== newSearchParams.gradePath) {
        setGradePath(newSearchParams.gradePath)
      }
      if (searchParams.sportPath !== newSearchParams.sportPath) {
        setCategory(newSearchParams.sportPath)
      }
      if (searchParams.decadePath !== newSearchParams.decadePath) {
        setDecadePath(newSearchParams.decadePath)
      }
      if (searchParams.yearPath !== newSearchParams.yearPath) {
        setYearPath(newSearchParams.yearPath)
      }
      if (searchParams.parentSetPath !== newSearchParams.parentSetPath) {
        setParentSetPath(newSearchParams.parentSetPath)
      }
      if (searchParams.setPath !== newSearchParams.setPath) {
        setSetPath(newSearchParams.setPath)
      }
      if (searchParams.subSearch !== newSearchParams.subSearch) {
        setSubSearch(newSearchParams.subSearch)
      }
      if (searchParams.playerId !== newSearchParams.playerId) {
        setPlayerId(newSearchParams.playerId)
      }
      updateSearchResults()
    },
    [
      searchParams,
      setSearchTerm,
      setSort,
      setAvailability,
      setListingFormat,
      setAttributes,
      setPlayerId,
      setTeamId,
      setOwnerName,
      setGradeActionPath,
      setGraderPath,
      setGradePath,
      setCategory,
      setDecadePath,
      setYearPath,
      setParentSetPath,
      setSetPath,
      setSubSearch,
      setPageNum,
      updateSearchResults,
    ]
  )

  useEffect(() => {
    setSearchParams((prevState) => {
      if (!prevState) return DEFAULT_SEARCH_REQUEST_VALUES(true)
      return {
        ...prevState,
        searchTerm: searchTerm || '',
        availability: availability as keyof typeof AvailabilityOptions,
        listingFormat: listingFormat as keyof typeof ListingFormatOptions,
        attributePaths: attributes,
        ownerName,
        playerId,
        teamId,
        gradeActionPath,
        gradePath,
        graderPath,
        sportPath: category,
        decadePath,
        yearPath,
        parentSetPath,
        setPath,
        subSearch,
        pageNum,
        sort: (sort as ITEM_SORT) || null,
        recentSearchID,
      }
    })
  }, [
    attributes,
    searchTerm,
    sort,
    pageNum,
    availability,
    listingFormat,
    ownerName,
    teamId,
    playerId,
    gradeActionPath,
    gradePath,
    graderPath,
    category,
    decadePath,
    yearPath,
    parentSetPath,
    setPath,
    subSearch,
    recentSearchID,
  ])

  useEffect(() => {
    if (recentSearchID) {
      prevSearchID.current = recentSearchID
      setSearchParams((prevState) => {
        return {
          ...prevState,
          recentSearchID,
        }
      })
    }
  }, [recentSearchID, updateSearchResults])

  useEffect(() => {
    if (!prevSearchID.current || recentSearchID) {
      updateSearchResults()
    }
  }, [searchParams, updateSearchResults, recentSearchID])

  useEffect(() => {
    if (prevSearchID.current) {
      return
    }
    if (
      attributes ||
      searchTerm ||
      sort ||
      pageNum ||
      availability ||
      listingFormat ||
      ownerName ||
      teamId ||
      playerId ||
      gradeActionPath ||
      gradePath ||
      graderPath ||
      category ||
      decadePath ||
      yearPath ||
      parentSetPath ||
      setPath ||
      subSearch
    ) {
      updateSearchResults()
    }
  }, [
    attributes,
    availability,
    category,
    decadePath,
    gradeActionPath,
    gradePath,
    graderPath,
    listingFormat,
    updateSearchResults,
    ownerName,
    pageNum,
    parentSetPath,
    playerId,
    searchTerm,
    setPath,
    sort,
    subSearch,
    teamId,
    yearPath,
  ])

  const searchResultsValue = useMemo(
    () => ({
      searchParams,
      updateSearchParams,
      searchResults,
      searchResultsLoading,
    }),
    [searchParams, searchResults, searchResultsLoading, updateSearchParams]
  )

  return <searchResultsContext.Provider value={searchResultsValue}>{children}</searchResultsContext.Provider>
}

export const useSearchResultsProvider = (): SearchResultsContext => useContext(searchResultsContext)
