import type { FC } from 'react'

import { signOut } from 'next-auth/react'
import { ListItemText, MenuItem, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'

const SignOutMenuItem: FC = () => {
  return (
    <MenuItem onClick={() => signOut({ callbackUrl: '/' })}>
      <ListItemText>Sign Out</ListItemText>
      <Typography color="text.secondary" ml={5}>
        <LogoutIcon sx={{ verticalAlign: 'middle' }} />
      </Typography>
    </MenuItem>
  )
}

export default SignOutMenuItem
