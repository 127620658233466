export enum userQueryKeys {
  GetFundsSummary = 'getFundsSummary',
  GetContactInformation = 'getContactInformation',
  GetUserInventory = 'getUserInventory',
  GetWatchList = 'getWatchList',
  GetPendingOffers = 'getPendingOffers',
  GetUserSummary = 'getUserSummary',
  GetUserAgreement = 'getUserAgreement',
  AcceptUserAgreement = 'acceptUserAgreement',
}
