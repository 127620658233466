import type { BrowseItemsByCategory } from '@/interfaces/navigation/browse'

import { type FC, useState } from 'react'
import { Button, Menu, MenuItem, Skeleton, Stack, Tooltip, Typography, listClasses, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import theme from '@/themes/comcTheme'
import { LOWER_APP_BAR_PAGES } from '@/content/lowerAppBar'
import Link from '@/components/ui/Link'
import Row from '@/components/ui/layout/Row'
import useBrowseCategories from '@/hooks/data/useBrowseCategories'
import { searchHref } from '@/constants'

const BrowseMenuLoadSection: FC = () => {
  return (
    <Stack spacing={1}>
      {Array.from(Array(8), (_, indx) => (
        <MenuItem key={indx}>
          <Skeleton variant="text" width="150px" sx={{ fontSize: '1rem' }} />
        </MenuItem>
      ))}
    </Stack>
  )
}

const BrowseMenu: FC = () => {
  const [browseMenuAnchorEl, setBrowseMenuAnchorEl] = useState<HTMLElement | null>(null)
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumScreenSize = useMediaQuery(theme.breakpoints.only('md'))
  const { browseList, isBrowseListLoading } = useBrowseCategories()

  const isBrowseMenuOpen = Boolean(browseMenuAnchorEl)

  const handleBrowseMenuClose = () => {
    setBrowseMenuAnchorEl(null)
  }

  const handleBrowseMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setBrowseMenuAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Button
        variant="outlined"
        startIcon={!isMediumScreenSize && <MenuIcon />}
        onClick={handleBrowseMenuOpen}
        aria-label="Browse Collectible Categories"
      >
        {!isMediumScreenSize ? (
          'Browse'
        ) : (
          <Tooltip title="Open Browse Menu">
            <MenuIcon />
          </Tooltip>
        )}
      </Button>

      <Menu
        anchorEl={browseMenuAnchorEl}
        id="browse-menu"
        open={isBrowseMenuOpen}
        onClose={handleBrowseMenuClose}
        PaperProps={{
          style: {
            width: isSmallScreenSize ? '50%' : '800px',
            maxWidth: '100%',
            padding: '0.5rem 0px',
            display: 'flex',
            maxHeight: '55%',
          },
        }}
        sx={{
          [`& .${listClasses.root}`]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: isSmallScreenSize ? 'nowrap' : 'wrap',
          },
        }}
      >
        {isBrowseListLoading && (
          <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            {Array.from(Array(3), (_, indx) => (
              <BrowseMenuLoadSection key={indx} />
            ))}
          </Stack>
        )}
        {!isBrowseListLoading &&
          browseList && [
            Object.entries(browseList as BrowseItemsByCategory).map(([key, value]) => (
              <Stack key={key} component="div" px={2} py={1 / 2}>
                <Typography variant="subtitle2" color="secondary">
                  {key}
                </Typography>
                {value.map(({ title, sportPath, search }) => (
                  <Stack key={title} component="div">
                    <Link
                      href={searchHref({ sportPath, searchTerm: search || '' })}
                      sx={{ textDecoration: 'none', color: 'unset' }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleBrowseMenuClose()
                        }}
                      >
                        {title}
                      </MenuItem>
                    </Link>
                  </Stack>
                ))}
              </Stack>
            )),
          ]}
        <Stack px={2} py={1 / 2}>
          <Typography variant="subtitle2" color="secondary">
            Browse
          </Typography>
          {LOWER_APP_BAR_PAGES.map((page) => (
            <MenuItem key={page.text} sx={{ color: page.color }}>
              <Row spacing={0.75}>
                <Link href={page.href} target={page.jumpout ? '_blank' : undefined} underline="none" color={page.color}>
                  {page.text}
                </Link>
                {page.icon && <page.icon fontSize="small" />}
              </Row>
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  )
}

export default BrowseMenu
