import type { ListingDetailsResult, Parallel, Player } from '@/interfaces/item/listingDetailsResult'
import type { ProductDetailsResults } from '@/interfaces/item/productDetailsResult'
import type { ProductDetailsParams, ProductSearchParams } from '@/interfaces/item/productRequest'
import type { SearchResultHit, SearchResultsData } from '@/interfaces/searchResult'
import type { SearchParams } from '@/interfaces/searchRequest'

import { useRouter } from 'next/router'
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import isEqual from 'lodash/isEqual'
import { useSession } from 'next-auth/react'

import { DEFAULT_SEARCH_REQUEST_VALUES } from '@/constants'
import useProductDetails from '@/hooks/data/useProductDetails'
import useListingDetails from '@/hooks/data/useListingDetails'
import { urlToProductSearchParams } from '@/helpers/productRequest'
import { clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'

type ProductDataContext = {
  productDetails?: ProductDetailsResults
  isProductDetailsLoading: boolean
  refetchProductDetails: () => void
  listingDetails?: ListingDetailsResult
  isListingDetailsLoading: boolean
  loadError?: boolean
  productSearchParams?: ProductSearchParams
  setProductSearchParams: Dispatch<SetStateAction<ProductSearchParams | undefined>>
  productSearch: (searchParams: ProductSearchParams, refresh?: boolean) => void
  cardParallels?: SearchResultHit[]
  cardGrades?: SearchResultHit[]
  otherCardsInSet?: SearchResultHit[]
  isDisableCartButtons: boolean
  setIsDisableCartButtons: Dispatch<SetStateAction<boolean>>
}

const productDataContext = createContext<ProductDataContext>({
  productDetails: undefined,
  isProductDetailsLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchProductDetails: () => {},
  listingDetails: undefined,
  isListingDetailsLoading: false,
  productSearchParams: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProductSearchParams: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  productSearch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // auctionSearch: () => {},
  isDisableCartButtons: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsDisableCartButtons: () => {},
})

/**
 * Provides data related to a product.
 * @param children - The child components.
 * @returns The JSX element.
 */
export default function ProductDataProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const [loadError, setLoadError] = useState<boolean>()
  const [cardParallels, setCardParallels] = useState<SearchResultHit[]>([])
  const [cardGrades, setCardGrades] = useState<SearchResultHit[]>([])
  const [otherCardsInSet, setOtherCardsInSet] = useState<SearchResultHit[]>([])
  const [productSearchParams, setProductSearchParams] = useState<ProductSearchParams>()
  const [isDisableCartButtons, setIsDisableCartButtons] = useState(false)
  const router = useRouter()
  const { data: session } = useSession()
  const { productDetails, isProductDetailsLoading, isProductDetailsError, refetchProductDetails } =
    useProductDetails(productSearchParams)
  const { listingDetails, isListingDetailsLoading, isListingDetailsError } = useListingDetails(
    productSearchParams?.productId,
    productSearchParams?.gradeId,
    productSearchParams?.ownerId
  )

  useEffect(
    () => setLoadError(isProductDetailsError || isListingDetailsError),
    [isListingDetailsError, isProductDetailsError]
  )

  const search = useCallback(
    async (searchParams: SearchParams) => {
      return clientAPIRequest<SearchResultsData, unknown>(
        '/api/search',
        RequestMethods.GET,
        { ...searchParams },
        session
      )
    },
    [session]
  )

  const filterOutCurrentCard = (cardsInSet: SearchResultHit[], gradeId: number, productId: number) => {
    return cardsInSet.filter((hit) => !(hit.productId === productId && hit.gradeId === gradeId))
  }

  // TODO: This code below should be replaced with a single API call, but for now we need to do this post processing to get the related card info
  const getRelatedCardInfo = useCallback(
    (
      listingPath: string,
      sportPath: string,
      parentSetPath: string,
      setName: string,
      parallels?: Parallel[],
      players?: Player[]
    ) => {
      if (!players || !parallels) return
      // preforming a search that is specific as possible
      search({
        ...DEFAULT_SEARCH_REQUEST_VALUES(false),
        sportPath,
        playerId: players.length ? players[0].playerId : null,
        parentSetPath,
        listingFormat: 'BUY_NOW',
      }).then((data: SearchResultsData) => {
        const availableListingPaths = parallels.map((x) => x.listingPath)
        if (availableListingPaths) {
          // find the search results that have a listing path that match an available parallel's listing path
          const parallelHits = data.searchResults.hits.filter((x) => {
            return x.listingPath && availableListingPaths.indexOf(x.listingPath) > -1
          })
          // filtering out other sets and the current grade
          const grades = parallelHits.filter((x) => {
            return setName === x.setName && x.gradeId !== productSearchParams?.gradeId
          })
          // This is supposed to sort from "highest" to "lowest" grade (i.e. PSA 9 vs PSA 8 ), but it the grade Ids aren't always reliable in terms of "best" to "worst"
          grades.sort((a, b) => {
            return a.gradeId - b.gradeId
          })
          setCardGrades(grades)
          // filters the current set from the results
          const parallels = parallelHits.filter((x) => {
            return x.listingPath !== listingPath
          })
          // returns a list of cards, should be one card to one parallel
          const parallelCards = reduceSearchResults(parallels, availableListingPaths)
          parallelCards.sort((a, b) => {
            return availableListingPaths.indexOf(a.listingPath) - availableListingPaths.indexOf(b.listingPath)
          })
          setCardParallels(parallelCards)
        }
      })
    },
    [productSearchParams?.gradeId, search]
  )

  useEffect(() => {
    if (
      !listingDetails ||
      !listingDetails.inventoryProducts ||
      !listingDetails.inventoryProducts.length ||
      !productDetails
    ) {
      return
    }
    const setPath = listingDetails.inventoryProducts[0].setPath || null
    if (listingDetails.ownerStats && listingDetails.ownerStats[0].listingPath) {
      getRelatedCardInfo(
        listingDetails.ownerStats[0].listingPath,
        productDetails.sportPath,
        productDetails.parentSetPath,
        productDetails.setName,
        listingDetails.parallels,
        listingDetails.players
      )
    }

    // finds the other cards in the set
    search({
      ...DEFAULT_SEARCH_REQUEST_VALUES(false),
      setPath,
    }).then((data: SearchResultsData) => {
      const cardsInSet = reduceSearchResults(data.searchResults.hits)
      const reducedResults = filterOutCurrentCard(cardsInSet, productDetails.gradeId, productDetails.productId)
      setOtherCardsInSet(reducedResults)
    })
  }, [productDetails, listingDetails, search, getRelatedCardInfo])

  /**
   * Reduces the search results by removing duplicates based on the listing path and selecting the most relevant card.
   * If uniquePaths are provided, it will use them to determine uniqueness; otherwise, it will use make a set from the listingPaths of the given search results.
   * @param results - The array of search results to be reduced.
   * @param uniquePaths - Optional. An array of unique paths to determine uniqueness.
   * @returns The reduced array of search results.
   */
  const reduceSearchResults = (results: SearchResultHit[], uniquePaths?: (string | undefined)[]): SearchResultHit[] => {
    const setPaths = uniquePaths || new Set(results.map((x) => x.listingPath))
    const reducedCards: SearchResultHit[] = []
    setPaths.forEach((setPath) => {
      // filters search result finding the cards that match a listing path
      const parallelEx = results.filter((x) => {
        return x.listingPath === setPath
      })
      if (parallelEx.length === 1) {
        reducedCards.push(parallelEx[0])
      } else if (parallelEx.length) {
        // prefer examples with no grade because they display better in the carousel
        const noGradeParallel = parallelEx.filter((x) => {
          return !x.grade
        })
        if (noGradeParallel.length) {
          reducedCards.push(noGradeParallel[0])
        } else {
          reducedCards.push(parallelEx[0])
        }
      }
    })
    return reducedCards
  }

  // TODO: Update this to use nuqs
  const updateRoute = useCallback(
    (isParamsUpdated: boolean, params: ProductSearchParams) => {
      if (router.pathname !== '/product' || isParamsUpdated) {
        Object.keys(params).forEach(
          (k) => params[k as keyof ProductSearchParams] == null && delete params[k as keyof ProductSearchParams]
        )
        router.push(
          {
            pathname: `/product`,
            query: { ...params },
          },
          undefined,
          { shallow: true }
        )
      }
    },
    [router]
  )

  useEffect(() => {
    if (router.pathname !== '/product') return
    const queryProductSearchParams = urlToProductSearchParams(router.query)
    if (isEqual(queryProductSearchParams, productSearchParams)) return
    setProductSearchParams(urlToProductSearchParams(router.query))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, router.pathname])

  const productSearch = useCallback(
    async (payload: ProductSearchParams, refresh?: boolean) => {
      const params = { ...productSearchParams, ...payload } as ProductDetailsParams
      const isParamsUpdated = !isEqual(productSearchParams, params)
      if (isProductDetailsLoading || (!isParamsUpdated && !refresh)) {
        return
      }

      setProductSearchParams((prevState) => {
        return {
          ...prevState,
          ...payload,
        }
      })

      setCardGrades([])
      setOtherCardsInSet([])
      updateRoute(isParamsUpdated, params)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productSearchParams, isProductDetailsLoading, updateRoute]
  )

  const productDataValue = useMemo(
    () => ({
      productSearchParams,
      setProductSearchParams,
      productDetails,
      isProductDetailsLoading,
      refetchProductDetails,
      listingDetails,
      isListingDetailsLoading,
      loadError,
      productSearch,
      cardParallels,
      cardGrades,
      otherCardsInSet,
      isDisableCartButtons,
      setIsDisableCartButtons,
    }),
    [
      productSearchParams,
      productDetails,
      refetchProductDetails,
      isProductDetailsLoading,
      listingDetails,
      isListingDetailsLoading,
      loadError,
      productSearch,
      cardParallels,
      cardGrades,
      otherCardsInSet,
      isDisableCartButtons,
    ]
  )

  return <productDataContext.Provider value={productDataValue}>{children}</productDataContext.Provider>
}

export const useProductDataProvider = (): ProductDataContext => useContext(productDataContext)
