import type { Option } from '@/interfaces/controls'

import { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

export const searchParamToAvailabilityOption = (param?: string) => {
  switch (param) {
    case 'Ignore':
      return 'AVAILABLE' as keyof typeof AvailabilityOptions
    case 'OnlySoldOut':
      return 'SOLD_OUT' as keyof typeof AvailabilityOptions
    case 'Include':
    default:
      return 'ALL' as keyof typeof AvailabilityOptions
  }
}

export const availabilityOptionToSearchParam = (option: keyof typeof AvailabilityOptions) => {
  const optionAsEnum = AvailabilityOptions[option]
  switch (optionAsEnum) {
    case AvailabilityOptions.AVAILABLE:
      return 'Ignore'
    case AvailabilityOptions.SOLD_OUT:
      return 'OnlySoldOut'
    case AvailabilityOptions.ALL:
    default:
      return 'Include'
  }
}

export const availabilityOptionToLabel = (option: AvailabilityOptions) => {
  switch (option) {
    case AvailabilityOptions.AVAILABLE:
      return 'Only display items available to purchase'
    case AvailabilityOptions.SOLD_OUT:
      return 'Only display items that are sold out'
    case AvailabilityOptions.ALL:
    default:
      return 'Display all items whether they are in stock or not'
  }
}

export const listingFormatOptionToLabel = (option: ListingFormatOptions) => {
  switch (option) {
    case ListingFormatOptions.BUY_NOW:
      return 'Only display items available to purchase now'
    case ListingFormatOptions.AUCTIONS:
      return 'Only display items that are available for auction'
    case ListingFormatOptions.ALL:
    default:
      return 'Display all items whether they are in available to buy now or up for auction'
  }
}

export const listingFormatOptionToSearchParam = (option: keyof typeof ListingFormatOptions) => {
  const optionAsEnum = ListingFormatOptions[option]
  switch (optionAsEnum) {
    case ListingFormatOptions.BUY_NOW:
      return 'BuyNow'
    case ListingFormatOptions.AUCTIONS:
      return 'Auction'
    case ListingFormatOptions.ALL:
    default:
      return 'All'
  }
}

export const searchParamToListingFormatOption = (param?: string) => {
  switch (param) {
    case 'BuyNow':
      return 'BUY_NOW' as keyof typeof ListingFormatOptions
    case 'Auction':
      return 'AUCTIONS' as keyof typeof ListingFormatOptions
    case 'All':
    default:
      return 'ALL' as keyof typeof ListingFormatOptions
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumOptionsToOptionsControl = (options: any, ariaLabelCb: (option: any) => any): Option[] => {
  return Object.keys(options).map((option) => {
    return {
      text: options[option as keyof typeof options],
      value: option,
      ariaLabel: ariaLabelCb(options[option as keyof typeof options]),
    }
  })
}
