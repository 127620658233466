export enum AvailabilityOptions {
  ALL = 'All Items',
  AVAILABLE = 'Available',
  SOLD_OUT = 'Sold Out',
}

export enum ListingFormatOptions {
  ALL = 'All Items',
  BUY_NOW = 'Buy Now',
  AUCTIONS = 'Auctions',
}
