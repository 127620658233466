export const MESSAGES = {
  PAGE_UNDER_DEVELOPMENT: 'This page is under development.',
  TECHNICAL_ERROR_MESSAGE: 'We ran into a problem. Please try again.',
  INVENTORY_ERROR_MESSAGE: 'We ran into a problem getting your inventory.',
  PRICE_UPDATE_ERROR_MESSAGE: 'We ran into issue updating price, try again.',
  GRADING_SUBMISSIONS_ERROR_MESSAGE: 'We ran into a problem getting your submissions for grading.',
  PURCHASE_HISTORY_ERROR_MESSAGE: 'We ran into a problem getting your purchase history.',
  ACTIVE_OFFER_ERROR_MESSAGE: 'We ran into a problem getting your active offers.',
  PAST_OFFER_ERROR_MESSAGE: 'We ran into a problem getting your past offers.',
  CLOSED_OFFER_ERROR_MESSAGE: 'We ran into a problem getting your closed offers.',
  SALES_HISTORY_ERROR_MESSAGE: 'We ran into a problem getting your sales history.',
  PAYMENT_ISSUE_ERROR_MESSAGE: 'There was an issue with your payment, please try again.',
  TRANSACTION_LOADING_ERROR_MESSAGE: 'There was an issue loading these transactions. Please try again.',
  SEARCH_ERROR_MESSAGE: 'There was a problem saving your search. Please try again later',
  OFFER_PAGE_LOAD_ERROR_MESSAGE: 'There was an error loading this page of offers, please try again.',
  OFFER_TRANSACTIONS_LOAD_ERROR_MESSAGE: 'There was an error loaading the offer transactions, please try again.',
  PROMOTIONS_LOAD_ERROR_MESSAGE: 'There was an issue loading these promotions. Please try again.',
  pages: {
    home: {
      title: 'Check Out My Baseball Cards, Comics & Collectibles',
      description:
        'Buy and sell baseball, football, basketball, and hockey cards online with COMC. Order from multiple sellers, but pay shipping one time! Find rookie cards, memorabilia, autographed cards, vintage, modern, and more on COMC.',
    },
  },
  SIGN_IN_BUTTON: 'Sign In/Sign Up',
  SIGN_IN_BUTTON_MOBILE: 'Sign In',
} as const

export default MESSAGES
