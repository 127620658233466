import type { SearchParams } from '@/interfaces/searchRequest'

import { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

export const DEFAULT_SEARCH_REQUEST_VALUES = (useLocalSettings = true): SearchParams => {
  return {
    searchTerm: '',
    pageNum: 1,
    hitsPerPage: 100,
    availability: Object.keys(AvailabilityOptions)[
      Object.values(AvailabilityOptions).indexOf(AvailabilityOptions.AVAILABLE)
    ] as keyof typeof AvailabilityOptions,
    listingFormat: Object.keys(ListingFormatOptions)[
      Object.values(ListingFormatOptions).indexOf(ListingFormatOptions.ALL)
    ] as keyof typeof ListingFormatOptions,
    sort:
      useLocalSettings && typeof localStorage !== 'undefined' && localStorage.getItem('sort')
        ? JSON.parse(localStorage.getItem('sort')!)
        : null,
    attributes: null,
    playerId: null,
    teamId: null,
    ownerName: null,
    gradeActionPath: null,
    graderPath: null,
    gradePath: null,
    yearPath: null,
    decadePath: null,
    sportPath: null,
    parentSetPath: null,
    setPath: null,
    subSearch: null,
    attributePaths: null,
    recentSearchID: null,
  } as SearchParams
}

export const MIN_YEAR = 1000
export const MAX_YEAR: number = Number.MAX_SAFE_INTEGER
