import type { ProfileMenuItem } from '@/interfaces/navigation/menuItem'

import { type FC, useEffect, useRef, useState } from 'react'
import { Button, Divider, IconButton, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LockIcon from '@mui/icons-material/Lock'
import { useSession } from 'next-auth/react'

import SignOutMenuItem from './SignOutMenuItem'
import { disableTooltipBasedOnLength, formatCurrency } from '@/helpers'
import { useUserProvider } from '@/store/userProvider'
import AvailableStoreCredit from '@/components/ui/user/AvailableStoreCredit'
import { addCreditHref, DASHBOARD_BASE_HREF, sellersHubHref } from '@/constants'
import { useAnimation } from '@/hooks/useAnimation'

const ProfileMenu: FC = () => {
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { data: session } = useSession()
  const { fundsSummary, userSummary, successfulAddToWatchlist, setSuccessfulAddToWatchlist } = useUserProvider()
  const profileIcon = useRef<HTMLButtonElement>()
  const { animateCSS } = useAnimation<HTMLButtonElement>()
  const isProfileMenuOpen = Boolean(profileMenuAnchorEl)

  const MENU_ITEMS: ProfileMenuItem[] = [
    {
      text: 'Dashboard',
      href: DASHBOARD_BASE_HREF,
    },
    {
      text: `Seller's Hub`,
      href: sellersHubHref,
    },
    {
      text: 'Profile',
      href: '/user/profile',
    },
  ]

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null)
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (successfulAddToWatchlist) {
      setSuccessfulAddToWatchlist(undefined)
      if (profileIcon.current) {
        animateCSS(profileIcon.current, 'headShake')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfulAddToWatchlist, setSuccessfulAddToWatchlist])

  return (
    <>
      <Stack alignItems="center" justify-content="center" spacing={0.25}>
        <Tooltip title="Open Profile Menu">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            data-test-id="profile-menu-button"
            ref={(e) => {
              if (e) {
                profileIcon.current = e
              }
            }}
            sx={{ p: 0.25 }}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={userSummary?.userName || session?.user?.name}
          disableHoverListener={disableTooltipBasedOnLength(userSummary?.userName || session?.user?.name || '', 10)}
        >
          <Typography
            variant="caption1"
            color="textSecondary"
            textOverflow="ellipsis"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 64,
            }}
          >
            {userSummary?.userName || session?.user?.name}
          </Typography>
        </Tooltip>
      </Stack>

      <Menu
        anchorEl={profileMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="profile-menu"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <Stack justifyContent="center" alignItems="center" component="div" pt={1} px={5} pb={2} spacing={2}>
          <Stack alignItems="center">
            <AccountCircle fontSize="large" />
            <Typography>
              Hello<span>, {userSummary?.userName || session?.user?.name}!</span>
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Tooltip title="Add Credit">
              <Button
                variant="contained"
                color="success"
                href={addCreditHref()}
                endIcon={fundsSummary && fundsSummary.pendingFunds > 0 && <LockIcon />}
              >
                <AvailableStoreCredit />
              </Button>
            </Tooltip>
            {fundsSummary && fundsSummary.pendingFunds > 0 && (
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1 / 2}>
                <LockIcon sx={{ fontSize: '0.75rem' }} />
                <Typography variant="caption1">Funds locked in pending offers: </Typography>
                <Typography variant="caption1" color="brandBlue.main" fontWeight="bold">
                  {formatCurrency(fundsSummary.pendingFunds)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Divider sx={{ my: 0.5 }} />
        {MENU_ITEMS.map((item) => (
          <MenuItem
            key={item.text}
            component={item.href ? 'a' : 'li'}
            href={item.href && item.href}
            onClick={item.onClick && item.onClick}
          >
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
        <Divider sx={{ my: 0.5 }} />
        <SignOutMenuItem />
      </Menu>
    </>
  )
}

export default ProfileMenu
